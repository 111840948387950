<template>
  
  <div class="main">
    <div class="header">
        <div class="navs">
          <ul>
            <li>主页</li>
            <li>常见问题</li>
            <li style="border-bottom: 2px solid #0088cc;">应用</li>
          </ul>
        </div>
        <div class="navs">
          <ul>
            <li style="">中文</li>
            <li class="img-text"> <img src="twitter.png"> 推特</li>
          </ul>

        </div>
    </div>
    <div class="main">
        <div>
          <img class="main-desk" src="desk.png">
        </div>
        <div>
          <a class="btn-download" :href="dd">
            Telegram 桌面版
          </a>
          <div class="mdesc">快速且安全的桌面应用程序，与您的手机完美同步</div>
        </div>
        <div class="btn-rad-parent">
          <a class="btn-rad" :href="dd">Telegram Windows X64</a>
        </div>
    </div>
    <div style="margin-top: 60px;">
        <div class="desc-sercet">This software is available under GPL v2 license.</div>
        <div class="desc-sercet">Source code is available on GitHub.</div>
    </div>
  </div>

</template>

<script>

import {serverUrl} from "./data/sm"
export default {
  name: 'App',
  components: {
  },
  data(){
    return {
      dd:serverUrl
    }
  }
}
</script>

<style>
#app {
  font-family: "Microsoft YaHei" ! important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.header{
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #e4e3e3;
}
.navs ul {
  display: flex;
  align-items: flex-end;
  margin-top: 12px;

  
}
.btn-download{
  display: block;
    text-decoration: none;
    padding: 15px;
    font-size: 28px;
    color: #383a3b;
    text-align: center;
    
}
.mdesc{
  font-size: 17px;
    margin: 0 auto;
    color: #67696a;
    margin: 14px 10px 24px;
    font-weight: 300;
}
.main-desk{
  width: 420px;
  height: 243px;
  background-size: contain;
    margin-top: 48px;
}
.img-text{
  display: flex;

}
.img-text img{
  margin-right: 10px;
}
.navs li{
  list-style: none;
  font-size: 15px;
  padding-top: 10px;
  padding-bottom:20px;
  margin: 0 20px;
  cursor: pointer;
  font-weight: 400;
  color: #0088cc;
  border-bottom: 2px solid #0088cc00;
}
.navs li:hover{
  border-bottom: 2px solid #0088cc !important;
}
.btn-rad{
 
  text-decoration: none;
    padding: 15px 60px;
    background-color: #409eff;
    color: #fff;
    border-radius: 10px;
}
.btn-rad-parent{
  margin-top: 50px;
}
.desc-sercet{
  margin-top: 30px;
  font-size: 14ppx;

}
</style>
